import React, {useEffect, useState} from 'react';
import {Button, Card, Col, Form, ListGroup, Row} from 'react-bootstrap';

import AbstractFileInput from "./AbstractFileInput";
import Grid from "@mui/material/Grid";
import {Checkbox, FormControlLabel} from "@mui/material";
import {headerDetailsSignal} from "../../store/signalsStore";
const headerDetails = headerDetailsSignal.value
const EditAbstractHeader = (props) => {

//WTF. Without setValue the onChange does not work.
    const [value, setValue] = useState("")

    const onChange = (e) => {
    setValue(e.target.value)
        headerDetailsSignal.value[e.target.name] = e.target.value
    }

    return (
        <>
            <Form>
                <Form.Label>{"Project"}</Form.Label>
                <Form.Control
                    type={"text"}
                    placeholder={"Enter Project"}
                    name={"project"}
                    value={headerDetails.project}
                    onChange={onChange}
                    isInvalid={false}
                />

                <Grid style={{marginLeft: "15px"}}>
                    <FormControlLabel control={<Checkbox checked={props.updated} onChange={() => {
                        props.setUpdated(!props.updated)
                        props.setLimited(false)
                    }

                    }/>}
                                      label="Updated Abstract?"/>
                </Grid>

                <Grid style={{marginLeft: "15px"}}>
                    <FormControlLabel control={<Checkbox checked={props.limited} onChange={() => {
                        props.setUpdated(false)
                        props.setLimited(!props.limited)
                    }}/>}
                                      label="Limited Abstract?"/>
                </Grid>
                {/*<Form.Label>{"Client"}</Form.Label>*/}
                {/*<Form.Control*/}
                {/*    type={"text"}*/}
                {/*    placeholder={"Enter Client"}*/}
                {/*    name={"client"}*/}
                {/*    value={headerDetailsSignal.value.client}*/}
                {/*    onChange={onChange}*/}
                {/*    isInvalid={false}*/}
                {/*/>*/}
                <Form.Label>{"County"}</Form.Label>
                <Form.Control
                    type={"text"}
                    placeholder={"Enter County"}
                    name={"county"}
                    value={headerDetailsSignal.value.county}
                    onChange={onChange}
                    isInvalid={false}
                />
                <Form.Label>{"Township and Range"}</Form.Label>
                <Form.Control
                    type={"text"}
                    placeholder={"Enter Legal"}
                    name={"townshipAndRange"}
                    value={headerDetailsSignal.value.townshipAndRange}
                    onChange={onChange}
                    isInvalid={false}
                />
                <Form.Label>{"Section"}</Form.Label>
                <Form.Control
                    type={"text"}
                    placeholder={"Enter Section"}
                    name={"section"}
                    value={headerDetailsSignal.value.section}
                    onChange={onChange}
                    isInvalid={false}
                />
                <Form.Label>{"Acreage"}</Form.Label>
                <Form.Control
                    type={"text"}
                    placeholder={"Enter Acreage"}
                    name={"acreage"}
                    value={headerDetailsSignal.value.acreage}
                    onChange={onChange}
                    isInvalid={false}
                />

                <Form.Label>{"Date Range for Report"}</Form.Label>
                <Form.Control
                    type={"text"}
                    placeholder={"Enter Date Range for Report"}
                    name={"date"}
                    value={headerDetailsSignal.value.date}
                    onChange={onChange}
                    isInvalid={false}
                />
                <Form.Label>{"Last Page"}</Form.Label>
                <Form.Control
                    type={"text"}
                    placeholder={"Enter Last Page"}
                    name={"lastPage"}
                    value={headerDetailsSignal.value.lastPage}
                    onChange={onChange}
                    isInvalid={false}
                />
                    {/*<Form.Label style={{marginTop: "20px", marginLeft: "20px"}}>{"Add Files:"}</Form.Label>*/}
                <AbstractFileInput abstractFiles={props.abstractFiles} setAbstractFiles={props.setAbstractFiles} client={headerDetailsSignal.value.client} project={headerDetailsSignal.value.project}/>
            </Form>
        </>
    )
}

export default EditAbstractHeader;